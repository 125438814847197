import React, { ChangeEventHandler } from 'react';
import styled, { DefaultTheme } from 'styled-components';
import { atBreakpoint } from '@minecraft.themes';
import { FlexDynamic } from './Containers';
import { BodyText } from './Typography';

const CheckboxWrapper = styled.label`
  display: inline-flex;
  position: relative;
  align-items: center;
  cursor: pointer;
  margin: 0;
`;

const CheckboxInput = styled.input`
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
`;

const getBackgroundColor = (theme: DefaultTheme, color: 'primary' | 'success', isLocked: boolean) => {
  const { bgColors } = theme.designSystem;
  if (color === 'success') {
    return isLocked ? bgColors.successDisabled : bgColors.successActive;
  }
  return isLocked ? bgColors.primaryDisabled : bgColors.primary;
};

const CheckboxSlider = styled.div<{ isLocked: boolean; checked: boolean; color: 'primary' | 'success' }>`
  width: 3rem;
  height: 1.8rem;
  background-color: ${({ theme, isLocked }) =>
    isLocked ? theme.designSystem.bgColors.disabled : theme.designSystem.bgColors.statusDefault};
  border-radius: 625rem;

  &:after {
    content: '';
    position: absolute;
    top: 0.2rem;
    left: -18px;
    background-color: ${({ theme, isLocked }) =>
      isLocked ? theme.designSystem.bgColors.toggleKnobDisabled : theme.designSystem.bgColors.toggleKnobActive};
    border: 1px solid ${({ theme }) => theme.designSystem.bgColors.toggleKnobActive};
    border-radius: 625rem;
    height: 1.4rem;
    width: 1.4rem;
    transition: all 0.3s;
    transform: translateX(100%);
    border-color: ${({ theme }) => theme.designSystem.bgColors.toggleKnobActive};
  }

  ${CheckboxInput}:checked + & {
    background-color: ${({ theme, color, isLocked }) => getBackgroundColor(theme, color, isLocked)};
    &:after {
      left: 0px;
    }
  }
`;

const CheckboxLabel = styled(BodyText)`
  ${atBreakpoint.sm`
  font-size: 4.3vw !important;
  `}
`;

export interface ToggleCheckboxProps {
  checked: boolean;
  isLocked?: boolean;
  onChange: ChangeEventHandler<HTMLInputElement>;
  className?: string;
  toggleOnText?: string;
  toggleOffText?: string;
  children?: React.ReactNode;
  ariaLabel?: string;
  color?: 'primary' | 'success';
  dataQaId?: string;
}

export const ToggleCheckbox: React.FC<ToggleCheckboxProps> = ({
  checked,
  onChange,
  className = '',
  toggleOnText = '',
  toggleOffText = '',
  isLocked = false,
  children = null,
  ariaLabel = 'ToggleCheckbox',
  color = 'primary',
  dataQaId = 'toggle-checkbox',
}) => {
  const handleToggleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!isLocked) {
      onChange(event);
    }
  };
  return (
    <FlexDynamic
      className={`cn_w-full cn_md:w-auto ${className}`}
      direction="row"
      gap="1"
      alignItems="center"
      justifyContent="space-between"
      grow={0}
      mdProps={{
        gap: '3',
        justifyContent: 'flex-end',
        direction: 'row',
        alignItems: 'center',
      }}
    >
      {toggleOffText && (
        <CheckboxLabel fontWeight="medium" color={checked ? 'disabled' : 'default'}>
          {toggleOffText}
        </CheckboxLabel>
      )}
      <CheckboxWrapper data-testid="checkbox-wrapper">
        <CheckboxInput
          type="checkbox"
          checked={checked}
          onChange={handleToggleChange}
          aria-label={ariaLabel}
          data-qa-id={dataQaId}
        />
        <CheckboxSlider isLocked={isLocked} checked={checked} color={color} />
      </CheckboxWrapper>
      {toggleOnText && (
        <CheckboxLabel color={checked ? 'default' : 'disabled'} fontWeight="medium">
          {toggleOnText}
        </CheckboxLabel>
      )}
      {children}
    </FlexDynamic>
  );
};
