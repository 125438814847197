export enum HEAP_EVENT_NAMES {
  REP_MEDIA_UPLOAD = 'Rep Media Upload',
  DISPLAY_UPGRADE_MODAL = 'Display Upgrade Modal',
  SUCCESSFUL_TALENT_SIGN_UP = 'Successful Talent Sign Up',
  HOVER_IN_MR_ICON_SCHEDULER = 'Hover In MR Icon-Scheduler',
  TALENT_LOGIN_NAV_SUCCESSFUL_LOGIN = 'Talent - Login - Nav - Successful Login',
  TALENT_LOGIN_NAV_FAILED_LOGIN = 'Talent - Login - Nav - Failed Login',
  TALENT_LOGIN_NAV_RESET_PASSWORD = 'Talent - Login - Nav - Reset Password',
  TALENT_LOGIN_NAV_LEGACY_RESET_PASSWORD = 'Talent - Login - Nav - Legacy Reset Password',
  TALENT_SOCIAL_ADD_LINK = 'Talent - Social - Add Link',
  TALENT_SOCIAL_FOLLOWER_COUNT_VIEW = 'Talent - Social - Follower Count View',
  TALENT_SOCIAL_LINK_ADD_SUCCESS = 'Talent - Social - Link Add Success',
  TALENT_SOCIAL_LINK_ADD_ERROR = 'Talent - Social - Link Add Error',
  TALENT_SOCIAL_CLICK_UPGRADE_TO_PREMIUM = 'Talent - Social - Click Upgrade to Premium',
  CD_INSTAGRAM_CLICK_BASEBALL_CARD = 'CD - Instagram - Click Baseball Card',
  CD_INSTAGRAM_CLICK_TALENT_PROFILE = 'CD - Instagram - Click Talent Profile',
  CD_INSTAGRAM_ACTION_ON_PROFILE = 'CD - Instagram - Action on Profile',
}
