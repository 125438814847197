import React, { FC, useMemo } from 'react';
import classNames from 'classnames';
import { useTheme } from 'styled-components';
import { Theme } from '@minecraft.themes';
import {
  computeBorderStyle,
  computeContainerStyle,
  computeBorderRadiusStyle,
  computeOffsetStyle,
  getGridItemStyles,
  getMarginClassnames,
  getWidthClassnames,
  getHeightClassnames,
} from '../helpers';
import { GridProps } from '../types';

const getGridStyles = ({
  columns = '1fr',
  rows = 'auto',
  autoRows,
  alignContent = 'stretch',
  alignItems = 'stretch',
  justifyContent = 'start',
  hidden = false,
  display = 'grid',
  theme,
  customBordersRadius,
  textColor,
  bgColor,
  bgOpacity,
  top,
  right,
  bottom,
  left,
  borderColor,
  borderPlacement,
  borderRadius,
  borderSize,
  borderBottom,
  borderStyle,
  customBorders,
}: GridProps & { theme: Theme }) => {
  return {
    display: hidden ? 'none' : display,
    gridTemplateColumns: columns,
    alignContent,
    alignItems,
    justifyContent,
    ...(autoRows ? { gridAutoRows: autoRows } : {}),
    ...(!autoRows ? { gridTemplateRows: rows } : {}),
    ...computeContainerStyle({ theme, textColor, bgColor, bgOpacity }),
    ...computeBorderStyle({
      theme,
      borderColor,
      borderPlacement,
      borderRadius,
      borderSize,
      borderBottom,
      borderStyle,
      customBorders,
    }),
    ...computeBorderRadiusStyle({ customBordersRadius }),
    ...computeOffsetStyle({ top, right, bottom, left }),
  };
};

export const GridBase: FC<GridProps> = ({
  children,
  id,
  className,
  'data-testid': dataTestId,
  'data-qa-id': dataQaId,
  gap = '1',
  borderRadius = 'none',
  zIndex,
  ...styleProps
}) => {
  const theme = useTheme() as Theme;
  const style = useMemo(() => getGridStyles({ ...styleProps, theme }), [styleProps, theme]);
  const gridItemStyle = useMemo(
    () => ({
      ...getGridItemStyles({ ...styleProps, theme }),
      theme,
    }),
    [styleProps, theme]
  );

  const qaIdValue = dataQaId;
  const optionalProps = useMemo(() => {
    return {
      ...(typeof dataTestId === 'string' && dataTestId ? { 'data-testid': dataTestId } : {}),
      ...(typeof qaIdValue === 'string' && qaIdValue ? { 'data-qa-id': qaIdValue } : {}),
    };
  }, [dataTestId, qaIdValue]);

  const classes = classNames(
    {
      [`cn_atom_zIndex-${zIndex}`]: zIndex !== undefined,
      [`cn_atom_gap-${gap}`]: true,
      [`cn_atom_rounded-${borderRadius}`]: !styleProps.customBordersRadius,
    },
    getMarginClassnames(styleProps),
    getWidthClassnames(styleProps),
    getHeightClassnames(styleProps),
    className
  );

  return (
    <div id={id} className={classes} {...optionalProps} style={{ ...gridItemStyle, ...style }}>
      {children}
    </div>
  );
};
