import { useMemo, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { QUERY_PARAMS, SESSION_COUPON_CODE } from '../constants';

const SESSION_COUPON_TYPE = 'session';

const codeKey: string = QUERY_PARAMS.couponCode;
const couponTypeKey: string = QUERY_PARAMS.couponType;
const planTermKey: string = QUERY_PARAMS.planTerm;
const deeplinkKey: string = QUERY_PARAMS.deeplink;

const useCouponCodeCatcher = () => {
  const history = useHistory();
  const [complete, setComplete] = useState<boolean>(false);
  const queryParams = useMemo(() => {
    return new URLSearchParams(history.location.search);
  }, [history.location.search]);
  const couponCode = queryParams.get(codeKey);
  const planTerm = queryParams.get(planTermKey);
  const deeplink = queryParams.get(deeplinkKey);
  const couponType = queryParams.get(couponTypeKey);
  const localStorageCode = localStorage.getItem(codeKey);
  const localStoragePlanTerm = localStorage.getItem(planTermKey);
  const localStorageDeeplink = localStorage.getItem(deeplinkKey);

  // if there is a coupon code on the query string, persist into local storage
  // so that it will be present the next time the user completes
  // an validated authentication
  useEffect(() => {
    const hasCouponCode = couponCode && localStorageCode !== couponCode;
    const hasPlanTerm = planTerm && localStoragePlanTerm !== planTerm;
    const hasDeeplink = deeplink && localStorageDeeplink !== deeplink;

    if (!complete && (hasCouponCode || hasPlanTerm || hasDeeplink)) {
      if (hasCouponCode) {
        // if the coupon code redemption is session based, set the session-based boolean in local storage
        if (couponType === SESSION_COUPON_TYPE) {
          localStorage.setItem(SESSION_COUPON_CODE, couponCode);
          queryParams.delete(couponTypeKey);
        } else {
          // set coupon code into localStorage
          localStorage.setItem(codeKey, couponCode);
        }
        // remove coupon code from the query params
        queryParams.delete(codeKey);
      }

      if (hasPlanTerm) {
        // set plan term into local storage
        localStorage.setItem(planTermKey, planTerm);
        // remove plan term from the query params
        queryParams.delete(planTermKey);
      }

      if (hasDeeplink) {
        // set deeplink into local storage
        localStorage.setItem(deeplinkKey, deeplink);
        // remove deeplink from the query params
        queryParams.delete(deeplinkKey);
      }

      history.replace({
        search: queryParams.toString(),
      });
    }

    if (!complete) {
      setComplete(true);
    }
  }, [
    couponCode,
    queryParams,
    history,
    localStorageCode,
    complete,
    planTerm,
    localStoragePlanTerm,
    deeplink,
    localStorageDeeplink,
    couponType,
  ]);

  return { complete };
};

export default useCouponCodeCatcher;
