import React, { FC, useState } from 'react';
import _isEmpty from 'lodash/isEmpty';
import { Image, CloudinaryComponentProps, Transformation, CropMode, Gravity } from 'cloudinary-react';
import { useTranslation } from '@blocs.i18n';
import {
  gqlMediaTransformation,
  gqlWfMediaTransformation,
  gqlMediaStorageStatusEnum as MEDIA_STORAGE_STATUS,
} from '@minecraft.graphql-types';
import { MediaTransformationFragment, BaseMediaFragment } from '@minecraft.graphql-operations';
import { constants, helpers } from '@minecraft.utils';
import { getEnvironment } from '@minecraft.environment';
import { StyledLinkWrapper, StyledLockedMediaBanner, StyledLockIcon, StyledImageWrapper } from './styles';

export const defaultSilhouetteUrl = 'defaults/profile-placeholder_de6qma.png';

const { commonPhotoTransformationParams, migratedPhotoTransformationParams } = constants;
const { getMediaDownloadOriginalUrl } = helpers;

export type SizeType = 'xxsm' | 'xsm' | 'sm' | 'md' | 'lg' | 'xlg' | 'xxlg' | 'xxxlg' | 'logo';

type PhotoTransformation = gqlMediaTransformation | gqlWfMediaTransformation;

type PhotoThumbnail = {
  url?: string;
  fileKey?: string;
  transformation?: Omit<BaseMediaFragment['transformation'], '__typename'>;
};

export type CloudinaryPhotoProps = {
  /**
   * @deprecated use `fileKey`, (Reason: we have structure on cn-hot-dev, for which to handle we should use fileKey)
   */
  guid?: string;
  fileKey: string;
  mediaStorageStatus?: any;
  index?: number | string;
  customSize?: [number, number];
  // This determines the image size we pull in, naming isnt super clear
  type?: SizeType;
  handleMediaClick?: (index: number | string) => void;
  ['data-qa-id']?: string;
  ['data-testid']?: string;
  url?: string;
  withDownloadLink?: boolean;
  legend?: () => JSX.Element;
  crop?: CropMode;
  thumbnailCropSettings?: {
    crop?: CropMode;
    gravity?: Gravity;
    zoom?: string;
  };
  background?: string;
  autoRotateDimensions?: boolean;
  showThumbnail?: boolean;
  isPhotoLocked?: boolean;
  thumbnail?: PhotoThumbnail;
  transformation?: gqlMediaTransformation | MediaTransformationFragment;
  showLandscape?: boolean;
  alt?: string;
  onError?: () => void;
} & Omit<CloudinaryComponentProps, 'transformation'>;

export const landscapeSizes: Record<SizeType, [number, number]> = {
  xxsm: [50, 40],
  xsm: [80, 64],
  sm: [180, 144],
  md: [240, 192],
  lg: [450, 360],
  xlg: [625, 500],
  xxlg: [800, 640],
  xxxlg: [1000, 800],
  logo: [84, 40],
};

const getFileKey = (
  showThumbnail: boolean,
  mediaMigrated: boolean,
  fileKey: string,
  thumbnail: CloudinaryPhotoProps['thumbnail']
) => {
  // All Migrated photos have thumbnail by default (it has different guid thus filekey)
  // But if photo has a transformation on the thumbanil
  // It means that it was edited after it was migrated
  // In that case, we should apply it on original photo
  // (only migrated assets have a CLD entity behind it's GUID)
  if (showThumbnail && mediaMigrated && !thumbnail?.transformation && thumbnail?.fileKey) {
    return thumbnail.fileKey;
  }

  return fileKey;
};

const getTransformation = (
  showThumbnail: boolean,
  transformation: CloudinaryPhotoProps['transformation'],
  thumbnail: CloudinaryPhotoProps['thumbnail']
) => {
  if (showThumbnail && thumbnail?.transformation) {
    return thumbnail.transformation;
  }

  if (!showThumbnail && transformation) {
    return transformation;
  }

  return null;
};

interface GetBaseCropParams {
  crop: CropMode;
  showThumbnail: boolean;
  mediaMigrated: boolean;
  showLandscape: boolean;
  thumbnailCropSettings?: CloudinaryPhotoProps['thumbnailCropSettings'];
  shownTransformation: PhotoTransformation;
}

const getBaseCropTransformation = ({
  crop,
  showThumbnail,
  shownTransformation,
  mediaMigrated,
  showLandscape,
  thumbnailCropSettings,
}: GetBaseCropParams): {
  crop: CropMode;
  gravity?: Gravity;
  zoom?: string;
  background?: string;
  thumbnailCropSettings?: CloudinaryPhotoProps['thumbnailCropSettings'];
} => {
  // If Photo does not have a default transformation/crop and we are showing a thumbnail
  // We should zoom into their face
  // If the media is migrated, it should have thumbnail specified in BAU
  if (showThumbnail && !shownTransformation && !mediaMigrated) {
    return {
      crop: 'thumb',
      gravity: 'face',
      zoom: '0.7',
    };
  }

  if (showLandscape) {
    return {
      crop: 'pad',
      background: 'black',
    };
  }

  return {
    crop,
    ...(!_isEmpty(thumbnailCropSettings) && thumbnailCropSettings),
  };
};

const CloudinaryPhoto: FC<CloudinaryPhotoProps> = ({
  fileKey = 'defaults/profile-placeholder_de6qma.png',
  cloudName,
  index,
  customSize,
  type = 'md',
  handleMediaClick,
  'data-qa-id': qaId,
  'data-testid': testId,
  url: primaryUrl,
  withDownloadLink = false,
  legend: Legend,
  crop = 'pad',
  thumbnailCropSettings,
  background = 'white',
  autoRotateDimensions = true,
  showThumbnail = false,
  thumbnail = null,
  transformation,
  isPhotoLocked = false,
  mediaStorageStatus,
  showLandscape = false,
  ...rest
}) => {
  const { t } = useTranslation();
  const commonImageProps = {
    cloudName: getEnvironment()?.CN_HOT_CLOUDNAME,
    'data-qa-id': qaId,
    'data-testid': testId,
    onClick: (): void => handleMediaClick && handleMediaClick(index),
    background,
    ...rest,
  };

  const [wideDimension, narrowDimension] = _isEmpty(customSize) ? landscapeSizes[type] : customSize;
  const hasDownloadLink = withDownloadLink && Boolean(primaryUrl);
  const mediaMigrated = mediaStorageStatus?.code === MEDIA_STORAGE_STATUS.BAU_MIGRATED;

  const shownTransformation = getTransformation(showThumbnail, transformation, thumbnail);
  const shownFileKey = getFileKey(showThumbnail, mediaMigrated, fileKey, thumbnail);
  const downloadUrl = getMediaDownloadOriginalUrl({
    hasDownloadLink,
    primaryUrl,
    fileKey,
  });
  const transitionIf = autoRotateDimensions ? 'ar_gt_1' : undefined;
  const transformationWidth = showLandscape ? wideDimension : narrowDimension;
  const transformationHeight = showLandscape ? narrowDimension : wideDimension;

  return (
    <>
      <StyledImageWrapper>
        <Image
          fetchFormat={commonPhotoTransformationParams.f}
          quality={commonPhotoTransformationParams.q}
          publicId={shownFileKey}
          {...commonImageProps}
          // DO NOT TOUCH, SHOULD NOT BE CHANGED
          secure
          secureDistribution={getEnvironment()?.CLD_DOMAIN}
        >
          {/* If Photo have been edited or has a thumbnail */}
          {shownTransformation && (
            <Transformation
              x={shownTransformation.xAxis}
              y={shownTransformation.yAxis}
              width={shownTransformation.width}
              height={shownTransformation.height}
              angle={shownTransformation.rotate}
              crop="crop"
            />
          )}
          {!shownTransformation && mediaMigrated && (
            <Transformation
              // Some of the images from Legacy have rotation in metadata
              // Which Cloudinary uses to rotate the photo, we should ignore that thus angle="ignore"
              angle={migratedPhotoTransformationParams.a}
            />
          )}
          {!showThumbnail ? (
            <>
              <Transformation
                if={transitionIf}
                width={wideDimension}
                height={narrowDimension}
                {...getBaseCropTransformation({
                  crop,
                  showThumbnail,
                  shownTransformation,
                  mediaMigrated,
                  showLandscape,
                })}
              />
              {autoRotateDimensions && (
                <Transformation
                  if="else"
                  width={transformationWidth}
                  height={transformationHeight}
                  {...getBaseCropTransformation({
                    crop,
                    showThumbnail,
                    shownTransformation,
                    mediaMigrated,
                    showLandscape,
                  })}
                />
              )}
            </>
          ) : (
            <>
              <Transformation
                width={narrowDimension}
                height={wideDimension}
                {...getBaseCropTransformation({
                  crop: 'lfill',
                  showThumbnail,
                  shownTransformation,
                  mediaMigrated,
                  showLandscape,
                  thumbnailCropSettings,
                })}
              />
            </>
          )}
        </Image>
        {isPhotoLocked && (
          <StyledLockedMediaBanner>
            <StyledLockIcon name="lock" /> {t('common:label.locked')}
          </StyledLockedMediaBanner>
        )}
      </StyledImageWrapper>
      {Legend && <Legend />}
      {hasDownloadLink && !isPhotoLocked && (
        <StyledLinkWrapper>
          <a href={downloadUrl} target="_blank" rel="noopener noreferrer" data-qa-id="photo-download-button">
            {t('common:button.download')}
          </a>
        </StyledLinkWrapper>
      )}
    </>
  );
};

// this component will try to load the photo, if it fails, it will load the default silhouette
export const CloudinaryPhotoWithFallback: FC<CloudinaryPhotoProps> = (props) => {
  const [loadingErr, setLoadingErr] = useState(false);

  return (
    <>
      {loadingErr ? (
        <CloudinaryPhoto
          {...props}
          thumbnail={null}
          transformation={null}
          withDownloadLink={false}
          fileKey={defaultSilhouetteUrl}
        />
      ) : (
        <CloudinaryPhoto {...props} onError={() => setLoadingErr(true)} />
      )}
    </>
  );
};

export default CloudinaryPhoto;
