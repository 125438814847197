import React from 'react';
import styled from 'styled-components';
import Icon from '@minecraft.icon';
import { DesignSystemBgColor, DesignSystemBoxShadowColor, DesignSystemTextColor } from 'components/themes';

type Variant = 'primary';
type Size = 'sm' | 'md' | 'lg';

type ButtonColors = {
  bgColor: DesignSystemBgColor;
  bgColorHover: DesignSystemBgColor;
  bgColorActive: DesignSystemBgColor;
  textColor: DesignSystemTextColor;
  textColorHover: DesignSystemTextColor;
  textColorDisabled: DesignSystemTextColor;
  focusOutline: DesignSystemBoxShadowColor;
};

const BUTTON_COLOR_MAP: Record<Variant, ButtonColors> = {
  primary: {
    bgColor: 'cardLightContrast',
    bgColorActive: 'primaryActive',
    bgColorHover: 'primaryHover',
    textColor: 'default',
    textColorHover: 'inverted',
    textColorDisabled: 'disabled',
    focusOutline: 'cardFocus',
  },
};

const SIZE_MAP: Record<Size, string> = {
  sm: '2.25rem',
  md: '3.25rem',
  lg: '4.25rem',
};

type Props = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  label: string;
  icon?: string;
  variant?: Variant;
  size?: Size;
};

const StyledCircleButton = styled.button<{ $disabled: boolean; $variant?: Variant; $size?: Size }>`
  display: flex;
  align-items: center;
  justify-content: center;

  border-width: 0rem !important;

  border-radius: 3rem;
  height: ${({ $size }) => SIZE_MAP[$size]};
  width: ${({ $size }) => SIZE_MAP[$size]};
  padding: 0.2rem;

  background-color: ${({ theme, $variant }) => theme.designSystem.bgColors[BUTTON_COLOR_MAP[$variant].bgColor]};
  color: ${({ theme, $variant, $disabled }) =>
    $disabled
      ? theme.designSystem.textColors[BUTTON_COLOR_MAP[$variant].textColorDisabled]
      : theme.designSystem.textColors[BUTTON_COLOR_MAP[$variant].textColor]};

  &:hover {
    background-color: ${({ theme, $variant, $disabled }) =>
      $disabled
        ? theme.designSystem.bgColors[BUTTON_COLOR_MAP[$variant].bgColor]
        : theme.designSystem.bgColors[BUTTON_COLOR_MAP[$variant].bgColorHover]};
    color: ${({ theme, $variant, $disabled }) =>
      $disabled
        ? theme.designSystem.textColors[BUTTON_COLOR_MAP[$variant].textColorDisabled]
        : theme.designSystem.textColors[BUTTON_COLOR_MAP[$variant].textColorHover]};
  }

  &:active {
    background-color: ${({ theme, $variant, $disabled }) =>
      $disabled
        ? theme.designSystem.bgColors[BUTTON_COLOR_MAP[$variant].bgColor]
        : theme.designSystem.bgColors[BUTTON_COLOR_MAP[$variant].bgColorActive]};
  }

  &:focus {
    outline: 0.25rem solid
      ${({ theme, $variant }) => theme.designSystem.boxShadows[BUTTON_COLOR_MAP[$variant].focusOutline]};
  }
`;

export const CircleButton: React.FC<Props> = ({
  icon,
  label,
  variant = 'primary',
  size = 'sm',
  disabled,
  ...props
}) => {
  return (
    <StyledCircleButton
      type="button"
      aria-label={label}
      $variant={variant}
      $size={size}
      $disabled={disabled}
      disabled={disabled}
      tabIndex={0}
      {...props}
    >
      {icon && <Icon name={icon} size={size} inheritColor />}
    </StyledCircleButton>
  );
};
