import { useFlags } from 'launchdarkly-react-client-sdk';
import { FeatureFlag } from './types';
import { FEATURE_FLAGS, MIGRATED_FEATURE_FLAGS } from './utils';

export const useFeatureExperiment = (featureName: FeatureFlag) => {
  const flags = useFlags();
  const flagName =
    featureName in FEATURE_FLAGS
      ? // if the featureName is a key of FEATURE_FLAGS, the flag name is the value of that key.
        // this is needed because of the typing 'keyof typeof FEATURE_FLAGS'
        FEATURE_FLAGS[featureName]
      : // otherwise, the featureName is already the flag name because a enumerated value in FEATURE_FLAGS was passed in
        featureName;

  // This short-cuts launch darkly to hard-code these values for migrated code
  // This prevents issues where users with LD endpoints blocked are still seeing older code when we are incrementally removing
  // code that may be behind what is the assumed "true" feature flag
  // LD also uses sockets, but the socket value will equal this value, this is primarily for users that are blocking LD
  // Lastly we only do this check when we are not in a unit test environment since we still want our unit tests to pass properly when they set the feature flags
  if (MIGRATED_FEATURE_FLAGS[flagName] && !process.env.TEST) {
    return MIGRATED_FEATURE_FLAGS[flagName];
  }

  return flags ? flags[flagName] : false;
};

/**
 * Returns the flags for subscription plan features
 */
export const useFeaturePlanFeatures = () => {
  const flags = useFlags();

  // All subscription plan features are prefixed with 'web-plan-feature-*'
  return Object.fromEntries(Object.entries(flags).filter(([key]) => key.startsWith('web-plan-feature-')));
};
