/* eslint-disable no-template-curly-in-string */
export const environment: {
  [key: string]: string;
} = {
  BASE_NAME: '${B21_BASE_NAME}',
  ENV: '${ENV}',
  API_URL: '${B21_API_URL}',
  AUDIENCE: '${B21_AUDIENCE}',
  TALENT_URL: '${B21_TALENT_URL}',
  REPS_URL: '${B21_REPS_URL}',
  CASTING_URL: '${B21_CASTING_URL}',
  PC_URL: '${B21_PC_URL}',
  ONBOARDING_URL: '${B21_ONBOARDING_URL}',
  ULA_URL: '${B21_ULA_URL}',
  LAUNCH_DARKLY_CLIENT_ID: '${B21_LAUNCH_DARKLY_ID}',
  GOOGLE_AUTH_CLIENT_ID: '${B21_GOOGLE_AUTH_CLIENT_ID}',
  GOOGLE_TAG_MANAGER_ID: '${B21_GOOGLE_TAG_MANAGER_ID}',
  GOOGLE_TAG_MANAGER_ENVIRONMENT_AUTH: '${B21_GOOGLE_TAG_MANAGER_ENVIRONMENT_AUTH}',
  GOOGLE_TAG_MANAGER_ENVIRONMENT_PREVIEW: '${B21_GOOGLE_TAG_MANAGER_ENVIRONMENT_PREVIEW}',
  SENTRY_DSN: '${B21_SENTRY_DSN}',
  B21_RELEASE: '${B21_RELEASE}',
  ALLOWED_COUNTRIES: '${B21_ALLOWED_COUNTRIES}',
  CDN_URL: '${B21_CDN_URL}',
  BRAZE_API_KEY: '${B21_BRAZE_API_KEY}',
  BRAZE_API_ENDPOINT: '${B21_BRAZE_API_ENDPOINT}',
  CLOUDFLARE_TURNSTILE_SITE_KEY: '${CLOUDFLARE_TURNSTILE_SITE_KEY}',
};
