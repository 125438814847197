import { getEnvironment } from '@minecraft.environment';
import { parseJwt } from '../Auth/utils/token';
import { PRODUCTION, STAGING, TEST } from '../Auth/constants';
import { HEAP_EVENT_NAMES } from './heapEventNames';

const environment = getEnvironment();

type HeapPrimitive = string | number | bigint | boolean | null | undefined;

/**
 * Set the identity of the current heap user using the access token
 */
export const setHeapIdentity = (accessToken: string) => {
  if ([PRODUCTION, TEST, STAGING].includes(environment.ENV)) {
    try {
      const parsedToken = parseJwt(accessToken);
      const heapIdentity = `${parsedToken?.role?.code}-${parsedToken?.id}`;

      if (parsedToken && window['heap']?.identify && window['heap']?.identity !== heapIdentity) {
        window['heap'].identify(heapIdentity);
      }
    } catch (e) {
      console.error(e);
    }
  }
};

/**
 * Clear the current heap user identity
 */
export const resetIdentity = () => {
  // heap user reset
  if ([PRODUCTION, TEST, STAGING].includes(environment.ENV)) {
    try {
      window['heap'].resetIdentity();
    } catch (e) {
      console.error(e);
    }
  }
};

/**
 * Add properties to the current user within heap
 * @param properties
 */
export const addUserProperties = (properties: Record<string, HeapPrimitive>) => {
  if ([PRODUCTION, TEST, STAGING].includes(environment.ENV)) {
    try {
      if (Object.values(properties).some((value) => typeof value === 'object')) {
        throw new Error('Heap properties must be primitives');
      }
      window['heap']?.addUserProperties(properties);
    } catch (e) {
      console.error(e);
    }
  }
};
/**
 * Add event properties to the current event within heap
 * @param properties
 */
export const addEventProperties = (properties: Record<string, HeapPrimitive>) => {
  if ([PRODUCTION, TEST, STAGING].includes(environment.ENV)) {
    try {
      window['heap']?.addEventProperties(properties);
    } catch (e) {
      console.error(e);
    }
  }
};

/**
 * Send a heap request with the current user (set via setIdentity)
 */
export const captureHeapEvent = ({
  name,
  data,
}: {
  name: HEAP_EVENT_NAMES | keyof typeof HEAP_EVENT_NAMES;
  data?: any;
}) => {
  if (getEnvironment()?.ENV !== PRODUCTION && !process.env.TEST) {
    console.warn('Heap event: ', name, data);
  }
  if ([PRODUCTION, TEST, STAGING].includes(environment.ENV)) {
    try {
      window['heap'].track(name, data);
    } catch (e) {
      console.error('Heap Capture Event Error: ', e);
    }
  }
};
