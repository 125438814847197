import { useMemo } from 'react';
import { Theme } from '@minecraft.themes';
import {
  BorderProps,
  ContainerStyleProps,
  CustomBorderProps,
  GridItemProps,
  HeightStyleProps,
  MarginStyleProps,
  WidthStyleProps,
} from './types';
import { WindowWidthContext } from '../../components/utils';

const convertNumericOpacityToHex = (numericOpacity: number): string => {
  let rawNumber = numericOpacity;

  if (typeof rawNumber !== 'number') {
    rawNumber = 1;
  }

  if (rawNumber > 1) {
    rawNumber = 1;
  }

  if (rawNumber < 0) {
    rawNumber = 0;
  }

  return Math.round(rawNumber * 255).toString(16);
};

export const computeContainerStyle = ({
  textColor,
  bgColor,
  theme,
  bgOpacity = 1,
  backgroundImage,
}: ContainerStyleProps & { theme: Theme }) => {
  const backgroundColor = theme.designSystem.bgColors[bgColor];
  return {
    backgroundColor: bgOpacity === 1 ? backgroundColor : `${backgroundColor}${convertNumericOpacityToHex(bgOpacity)}`,
    color: theme.designSystem.textColors[textColor],
    ...(backgroundImage?.image
      ? {
          backgroundImage: `url(${backgroundImage.image})`,
          backgroundSize: backgroundImage.size || 'cover',
          backgroundPosition: backgroundImage.position || 'center',
          backgroundRepeat: backgroundImage.repeat || 'no-repeat',
        }
      : {}),
  };
};

const buildBorderString = ({
  borderColor = 'default',
  borderSize = 0.06,
  borderStyle = 'solid',
  theme,
}: CustomBorderProps & { theme: Theme }) => {
  return `${borderSize}rem ${borderStyle} ${theme.designSystem.borderColors[borderColor]}`;
};

const formatValue = (value?: number) => {
  return typeof value === 'number' ? `${value}rem` : undefined;
};

export const computeBorderRadiusStyle = ({ customBordersRadius }: BorderProps) => {
  if (!customBordersRadius) {
    return {};
  }
  return {
    borderTopLeftRadius: formatValue(customBordersRadius.topLeft),
    borderTopRightRadius: formatValue(customBordersRadius.topRight),
    borderBottomLeftRadius: formatValue(customBordersRadius.bottomLeft),
    borderBottomRightRadius: formatValue(customBordersRadius.bottomRight),
  };
};

export const computeOffsetStyle = ({ top, right, bottom, left }: BorderProps) => {
  return {
    top: formatValue(top),
    right: formatValue(right),
    bottom: formatValue(bottom),
    left: formatValue(left),
  };
};

export const computeBorderStyle = ({
  borderColor = 'default',
  borderBottom = false,
  borderSize = 0.06,
  borderPlacement = 'none',
  borderStyle = 'solid',
  customBorders = {},
  theme,
}: BorderProps & { theme: Theme }) => {
  if (borderPlacement === 'none' && borderBottom) {
    return {
      borderBottom: borderBottom ? `1px ${borderStyle} ${theme.common.borderColor}` : '',
    };
  }

  if (Object.keys(customBorders).length > 0) {
    // build custom border styles object
    return {
      ...(customBorders?.top ? { borderTop: buildBorderString({ ...customBorders?.top, theme }) } : {}),
      ...(customBorders?.left ? { borderLeft: buildBorderString({ ...customBorders?.left, theme }) } : {}),
      ...(customBorders?.right ? { borderRight: buildBorderString({ ...customBorders?.right, theme }) } : {}),
      ...(customBorders?.bottom ? { borderBottom: buildBorderString({ ...customBorders?.bottom, theme }) } : {}),
    };
  }

  const borderValue = buildBorderString({ borderColor, borderStyle, borderSize, theme });

  if (borderPlacement === 'all') {
    return { border: borderValue };
  }

  if (borderPlacement === 'x') {
    return {
      borderLeft: borderValue,
      borderRight: borderValue,
    };
  }

  if (borderPlacement === 'y') {
    return {
      borderTop: borderValue,
      borderBottom: borderValue,
    };
  }

  if (borderPlacement === 'left') {
    return { borderLeft: borderValue };
  }

  if (borderPlacement === 'right') {
    return { borderRight: borderValue };
  }

  if (borderPlacement === 'top') {
    return { borderTop: borderValue };
  }

  if (borderPlacement === 'bottom') {
    return { borderBottom: borderValue };
  }

  return {};
};

export const getGridItemStyles = ({
  gridColumn = 'auto',
  gridRow,
  gridColumnStart,
  gridColumnEnd,
  gridRowStart,
  gridRowEnd,
  order,
  theme,
  justifyItems,
  alignItems,
  justifySelf,
  alignSelf,
  ...restProps
}: Omit<GridItemProps, 'className' | 'children'> & { theme: Theme }) => {
  return {
    ...(gridColumnStart || gridColumnEnd ? { gridColumnStart, gridColumnEnd } : { gridColumn }),
    ...(gridRowStart || gridRowEnd ? { gridRowStart, gridRowEnd } : { gridRow }),
    order,
    justifyItems,
    alignItems,
    justifySelf,
    alignSelf,
    ...computeContainerStyle({ theme, ...restProps }),
    ...computeBorderStyle({ theme, ...restProps }),
    ...computeBorderRadiusStyle(restProps),
    ...computeOffsetStyle({ ...restProps }),
  };
};

type BreakpointName = '' | 'sm' | 'md' | 'lg' | 'xl';

/**
 * Helper class for the getMarginClassnames function
 * @param breakpoint
 * @param margins
 */
const generateMarginClassesForBreakpoint = (breakpoint: BreakpointName, margins?: MarginStyleProps) => {
  const breakpointString = breakpoint ? `${breakpoint}:` : '';

  if (!margins) return {};
  return {
    [`cn_${breakpointString}m-${margins.m}`]: margins.m !== undefined,
    [`cn_${breakpointString}mx-${margins.mx}`]: margins.mx !== undefined,
    [`cn_${breakpointString}my-${margins.my}`]: margins.my !== undefined,
    [`cn_${breakpointString}mt-${margins.mt}`]: margins.mt !== undefined,
    [`cn_${breakpointString}mr-${margins.mr}`]: margins.mr !== undefined,
    [`cn_${breakpointString}mb-${margins.mb}`]: margins.mb !== undefined,
    [`cn_${breakpointString}ml-${margins.ml}`]: margins.ml !== undefined,
  };
};

/**
 * @deprecated
 * Using the javascript window resizing we merge together responsive props
 *
 * This causes a re-render of this component when traveling through breakpoints
 * When/if creating new components use the classNames for breakpoint selections similar
 * to BasicContainer that produces `md:class` and `lg:class` etc.
 */
export const useMergedResponsiveProps = ({ props, smProps, mdProps, lgProps, xlProps }) => {
  const { moreSm, moreMd, moreLg, moreXlg } = WindowWidthContext.useWidthData();

  return useMemo(() => {
    if (!moreSm) {
      return props;
    }

    if (moreSm && !moreMd) {
      return { ...props, ...smProps };
    }

    if (moreMd && !moreLg) {
      return { ...props, ...smProps, ...mdProps };
    }

    if (moreLg && !moreXlg) {
      return { ...props, ...smProps, ...mdProps, ...lgProps };
    }

    return { ...props, ...smProps, ...mdProps, ...lgProps, ...xlProps };
  }, [moreSm, moreMd, moreLg, moreXlg, props, smProps, mdProps, lgProps, xlProps]);
};

/**
 * Takes the margin style props and returns the proper tailwind classnames
 * @example
 *   <FlexDynamic mr="4" mdProps={{ mr: "0" }} />
 *   <BasicContainer m="2" mdProps={{ mx: "2" }} />
 *
 * Within a component it simply adds it to the className prop
 * with `styleProps` = `...styleProps` handed to the component
 * className={classNames(getMarginClassnames(styleProps), className)}
 */
export const getMarginClassnames = (
  margins: MarginStyleProps,
  /* sm margins turn into the sm: classnames similar to Tailwind */
  smMargins?: MarginStyleProps,
  /* md margins turn into the md: classnames similar to Tailwind */
  mdMargins?: MarginStyleProps,
  /* lg margins turn into the lg: classnames similar to Tailwind */
  lgMargins?: MarginStyleProps,
  /* xl margins turn into the xl: classnames similar to Tailwind */
  xlMargins?: MarginStyleProps
) => {
  // The ordering of what is returned doesn't matter due to CSS's nature of
  // using whatever is defined first in the stylesheet as higher priority
  return {
    ...generateMarginClassesForBreakpoint('', margins),
    ...generateMarginClassesForBreakpoint('sm', smMargins),
    ...generateMarginClassesForBreakpoint('md', mdMargins),
    ...generateMarginClassesForBreakpoint('lg', lgMargins),
    ...generateMarginClassesForBreakpoint('xl', xlMargins),
  };
};

const generateWidthClassesForBreakpoint = (breakpoint: BreakpointName, width?: WidthStyleProps) => {
  const breakpointString = breakpoint ? `${breakpoint}:` : '';

  if (!width) return {};
  return {
    [`cn_${breakpointString}w-${width.w}`]: width.w !== undefined,
    [`cn_${breakpointString}max-w-${width.maxW}`]: width.maxW !== undefined,
    [`cn_${breakpointString}min-w-${width.minW}`]: width.minW !== undefined,
  };
};

export const getWidthClassnames = (
  width: WidthStyleProps,
  /* sm width that turn into sm: classnames similar to Tailwind */
  smWidth?: WidthStyleProps,
  /* md width that turn into md: classnames similar to Tailwind */
  mdWidth?: WidthStyleProps,
  /* lg width that turn into lg: classnames similar to Tailwind */
  lgWidth?: WidthStyleProps,
  /* xl width that turn into xl: classnames similar to Tailwind */
  xlWidth?: WidthStyleProps
) => {
  return {
    ...generateWidthClassesForBreakpoint('', width),
    ...generateWidthClassesForBreakpoint('sm', smWidth),
    ...generateWidthClassesForBreakpoint('md', mdWidth),
    ...generateWidthClassesForBreakpoint('lg', lgWidth),
    ...generateWidthClassesForBreakpoint('xl', xlWidth),
  };
};

const generateHeightClassesForBreakpoint = (breakpoint: BreakpointName, height?: HeightStyleProps) => {
  const breakpointString = breakpoint ? `${breakpoint}:` : '';

  if (!height) return {};
  return {
    [`cn_${breakpointString}h-${height.h}`]: height.h !== undefined,
    [`cn_${breakpointString}max-h-${height.maxH}`]: height.maxH !== undefined,
    [`cn_${breakpointString}min-h-${height.minH}`]: height.minH !== undefined,
  };
};

export const getHeightClassnames = (
  height: HeightStyleProps,
  /* sm height that turn into sm: classnames similar to Tailwind */
  smHeight?: HeightStyleProps,
  /* md height that turn into md: classnames similar to Tailwind */
  mdHeight?: HeightStyleProps,
  /* lg height that turn into lg: classnames similar to Tailwind */
  lgHeight?: HeightStyleProps,
  /* xl height that turn into xl: classnames similar to Tailwind */
  xlHeight?: HeightStyleProps
) => {
  return {
    ...generateHeightClassesForBreakpoint('', height),
    ...generateHeightClassesForBreakpoint('sm', smHeight),
    ...generateHeightClassesForBreakpoint('md', mdHeight),
    ...generateHeightClassesForBreakpoint('lg', lgHeight),
    ...generateHeightClassesForBreakpoint('xl', xlHeight),
  };
};
