import { ReactText } from 'react';
import _isBoolean from 'lodash/isBoolean';
import _isEmpty from 'lodash/isEmpty';
import _isNaN from 'lodash/isNaN';
import _reduce from 'lodash/reduce';
import _split from 'lodash/split';
import _orderBy from 'lodash/orderBy';
import _groupBy from 'lodash/groupBy';
import _sortBy from 'lodash/sortBy';
import _sumBy from 'lodash/sumBy';
import _find from 'lodash/find';
import _take from 'lodash/take';
import _partition from 'lodash/partition';
import _includes from 'lodash/includes';
import _toLower from 'lodash/toLower';
import { GraphQLError } from 'graphql';
import { CropMode } from 'cloudinary-react';
import moment from 'moment-timezone';
import { useTranslation } from '@blocs.i18n';
import { SelectOption } from '@minecraft.typings';
import {
  gqlCredit,
  gqlTimeZone,
  gqlEthnicAppearance,
  gqlGenderAppearance,
  gqlMedia,
  gqlWfMedia,
  gqlMediaTransformation,
  gqlWfMediaTransformation,
  gqlProfile,
  gqlCountryEnum as COUNTRY,
  gqlPaidTypeEnum as PAID_TYPE,
  gqlTalentUnionEnum as TALENT_UNION,
  gqlEthnicAppearanceEnum as ETHNIC_APPEARANCE,
  gqlGenderAppearanceEnum as GENDER_APPEARANCE,
  gqlUnitSystemEnum as UNIT_SYSTEM,
  gqlMediaTypeCodeEnum as MEDIA_TYPE,
  gqlMediaStorageStatusEnum as MEDIA_STORAGE_STATUS,
  gqlTalentProfileUnion,
} from '@minecraft.graphql-types';
import { BaseMediaFragment, UserContextFragment, CreditOnProfileFragment } from '@minecraft.graphql-operations';
import { getEnvironment } from '@minecraft.environment';
import {
  profileConstants,
  instanceConstants,
  NO_COUNTRY_RESTRICTIONS,
  commonPhotoTransformationParams,
  videoTransformationParams,
  audioTransformationParams,
  migratedPhotoTransformationParams,
} from '../constants';
import { BILLING_ITEM_CODES, BILLING_CREDITS_MODEL } from '../billingConstants';
import ErrorMessages from './ErrorMessages';

interface GraphQLErrorCN extends GraphQLError {
  data?: {
    errorCode?: number;
  };
}

// ErrorType is actually readonly it's type of new Error()
export type ErrorDataType = {
  graphQLErrors?: readonly GraphQLErrorCN[];
};

export const mediaMap = (media) => {
  const mediaList = {
    VIDEO: [],
  };

  !_isEmpty(media) && mediaList[media.mediaType.code].push(media);

  return mediaList;
};

export const parseErrorResponse = (data) =>
  (data.errors ?? data.graphQLErrors ?? [])
    .map((e) => {
      if (e) {
        let errors = [];

        if (e.data && e.data.errorData) {
          ({ errors } = e.data.errorData);
        }

        if (
          e.data &&
          e.data.internalData &&
          e.data.internalData.internalMessage &&
          e.data.internalData.internalMessage.publicData
        ) {
          ({ errors } = e.data.internalData.internalMessage.publicData);
        }

        if (errors && errors.length) {
          return errors.join(', ');
        }

        return e.message || e?.data?.errorData?.message;
      }

      return '';
    })
    .filter((e) => !!e);

export function findArtistByProfileId(artists: UserContextFragment['artists'], profileId: string | number) {
  if (!Array.isArray(artists) || !profileId) return null;

  return artists.reduce((acc, artist) => {
    if (!acc && artist.profiles.filter((p) => p.profileId.toString() === profileId.toString()).length) {
      return artist;
    }

    return acc;
  }, null);
}

export const convertMonthToYear = (months) => {
  if (months % 12 > 6) return Math.ceil(months / 12);

  return Math.floor(months / 12);
};

export const convertYearToMonth = (years) => {
  return Math.ceil(years * 12);
};

export const checkDOB = (dob) => {
  const enteredDate = Date.parse(dob);

  if (_isNaN(enteredDate)) return false;

  return enteredDate < new Date().getTime();
};

export const getMediaSize = (mediaSizeInBytes, convertToSizeUnit) => {
  const unit = 1024;

  switch (convertToSizeUnit) {
    case 'KB':
      return mediaSizeInBytes / unit;
    case 'MB':
      return mediaSizeInBytes / unit / unit;
    case 'GB':
      return mediaSizeInBytes / unit / unit / unit;
    case 'TB':
      return mediaSizeInBytes / unit / unit / unit / unit;
    default:
      return '';
  }
};

export const isValidFileExtension = (fileType, validExtensions) => {
  const [, fileExt] = _split(fileType, '/');

  return fileExt && validExtensions.includes(fileExt.toLowerCase());
};

export const isValidMinimumFileSize = (fileMinSize, minSizeIdentifier) => {
  const minSizeIdentifierArray = minSizeIdentifier.split('_');

  if (minSizeIdentifierArray && minSizeIdentifierArray.length === 2) {
    const [minSizeLimit, minSizeUnit] = minSizeIdentifierArray;
    const mediaSize = getMediaSize(fileMinSize, minSizeUnit);

    if (mediaSize < Number(minSizeLimit)) {
      return false;
    }
  }

  return true;
};

export const isValidMaximumFileSize = (fileMaxSize, maxSizeIdentifier) => {
  const maxSizeIdentifierArray = maxSizeIdentifier.split('_');

  if (maxSizeIdentifierArray && maxSizeIdentifierArray.length === 2) {
    const [maxSizeLimit, maxSizeUnit] = maxSizeIdentifierArray;
    const mediaSize = getMediaSize(fileMaxSize, maxSizeUnit);

    if (mediaSize > Number(maxSizeLimit)) {
      return false;
    }
  }

  return true;
};

export const createCreditsMap = <T extends CreditOnProfileFragment | gqlCredit>(
  credits: T[] | readonly T[]
): Record<string, T[]> => {
  let creditsMap: Record<string, T[]> = {};

  if (credits) {
    const sortedCredits = _orderBy(
      credits,
      ['creditOrder', 'year', 'title', 'role', 'director'],
      ['desc', 'desc', 'asc', 'asc', 'asc']
    );

    creditsMap = _groupBy(sortedCredits, (c) => c?.creditType?.name || '');
  }

  return creditsMap;
};

export type SortedCreditsGroupedByType<T extends CreditOnProfileFragment | gqlCredit> = {
  creditType: T['creditType'];
  sortOrder: number;
  profileCreditTypeId: number;
  credits: T[];
};

export const getCreditTypesSortedArr = <T extends CreditOnProfileFragment | gqlCredit>(
  creditsMap: Record<string, T[]>
): SortedCreditsGroupedByType<T>[] => {
  const creditTypesSortedArr: SortedCreditsGroupedByType<T>[] = [];

  Object.keys(creditsMap).forEach((key) => {
    const firstCredit = creditsMap[key][0];

    creditTypesSortedArr.push({
      creditType: firstCredit.creditType,
      sortOrder: firstCredit.sortOrder,
      profileCreditTypeId: firstCredit.profileCreditTypeId,
      credits: creditsMap[key],
    });
  });

  // sort the list of groups by the sortOrder
  return _orderBy(creditTypesSortedArr, ['sortOrder'], ['asc']);
};

export const getSortedCreditsGroupedByType = <T extends CreditOnProfileFragment | gqlCredit>(
  credits: T[] | readonly T[]
): SortedCreditsGroupedByType<T>[] => {
  return getCreditTypesSortedArr(createCreditsMap(credits));
};

export const uniqueId = (prefix) => `${prefix}${Math.random().toString(36).substring(2, 9)}`;

export const extractMetaData = (mediaType, file) =>
  new Promise((resolve) => {
    switch (mediaType) {
      case MEDIA_TYPE.AUDIO: {
        let fileSize = file.size;

        // here we use correction factors calculated using specific examples (mp3: 1.1, aac: 0.75, ogg: 2.2)
        // eslint-disable-next-line sonarjs/no-nested-switch
        switch (file.type) {
          case 'audio/mp3':
            fileSize *= 1.1;
            break;
          case 'audio/aac':
            fileSize *= 0.75;
            break;
          case 'audio/ogg':
            fileSize *= 2.2;
            break;
          default:
            resolve(null);
        }

        const audio = document.createElement('audio');

        audio.addEventListener(
          'loadedmetadata',
          function onload() {
            resolve({ sampleRate: fileSize / this.duration });
          },
          false
        );
        audio.src = URL.createObjectURL(file);
        break;
      }
      case MEDIA_TYPE.VIDEO: {
        const video = document.createElement('video');

        video.addEventListener(
          'loadedmetadata',
          function onload() {
            resolve({
              width: this.videoWidth,
              height: this.videoHeight,
            });
          },
          false
        );
        video.src = URL.createObjectURL(file);
        break;
      }
      case MEDIA_TYPE.PHOTO: {
        const img = new Image();

        img.onload = function onload() {
          resolve({
            width: this.width,
            height: this.height,
          });
        };
        img.src = URL.createObjectURL(file);
        break;
      }
      default:
        resolve(null);
    }
  });

export const validateMetadata = (metaData, metaDataSettings) => {
  const errors = [];

  if (metaData) {
    if (metaDataSettings.minHeight && metaData.height < metaDataSettings.minHeight) {
      errors.push('MIN_HEIGHT_VALIDATION');
    }

    if (metaDataSettings.minWidth && metaData.width < metaDataSettings.minWidth) {
      errors.push('MIN_WIDTH_VALIDATION');
    }

    if (metaDataSettings.sampleRate && metaData.sampleRate <= metaDataSettings.sampleRate * 1000) {
      errors.push('MIN_RATE_VALIDATION');
    }
  }

  return errors;
};

export const checkDuplicateBookOutDates = (selectedDateValues, activeBookOuts) => {
  if (!activeBookOuts || !selectedDateValues) return null;

  const isSameFromAndToDate =
    selectedDateValues.bookAt === null &&
    selectedDateValues.bookFrom.toDateString() === selectedDateValues.bookTo.toDateString();

  if (selectedDateValues.bookAt !== null || isSameFromAndToDate) {
    const dateToCompare = isSameFromAndToDate
      ? selectedDateValues.bookFrom.toDateString()
      : selectedDateValues.bookAt.toDateString();

    return (
      activeBookOuts.filter(
        (data) => data.endDate === null && new Date(data.startDate).toDateString() === dateToCompare
      ).length > 0
    );
  }

  return (
    activeBookOuts.filter(
      (data) =>
        new Date(data.startDate).toDateString() === selectedDateValues.bookFrom.toDateString() &&
        new Date(data.endDate).toDateString() === selectedDateValues.bookTo.toDateString()
    ).length > 0
  );
};

// this function takes all timezones (from db) and parses the array
// if there is item that matches browser timezone -> returns its timezone, otherwise returns the timezone of array's first element
export const getBrowserTimezone = <T extends Pick<gqlTimeZone, 'codeBrowser' | 'code'>>(timeZones: T[]): T => {
  const userTime = moment.tz.guess().toUpperCase().replace('/', '_');

  // here we need to check both - code and browserCode from DB, as firefox is giving different result
  const userTimeZone =
    timeZones?.length && timeZones.find((time) => userTime === time.codeBrowser || userTime === time.code);

  // if no value was found -> set first from timeZones
  return userTimeZone || timeZones?.[0];
};

// returns error messages based on code from API
export const getErrorMessage = (error: ErrorDataType): string => {
  const errorObject = error?.graphQLErrors?.[0] || error;
  // changes after API error object change - now we need to handle errorCode from exeption if it exists
  const code = (errorObject as any)?.extensions?.exception?.errorCode || (errorObject as any)?.data?.errorCode;

  return ErrorMessages[code] || '';
};

export const getErrorMessageFromGqlError = (error): string => {
  if (!error) return '';

  const errorObject = error?.graphQLErrors?.[0];

  return (
    errorObject?.data?.errorData?.message ||
    errorObject?.extensions?.exception?.message ||
    errorObject?.data?.message ||
    error.message
  );
};

export const sortUnions = (unions) => {
  // Sort by sortOrder from DB
  const byOrder = _sortBy(unions, ['sortOrder']);
  const byParent = [];

  // Putting child elements beneath parent element (It's a flat array)
  byOrder.forEach((union) => {
    const parentId = union.parentObject ? union.parentObject.id : null;
    const parentIndex = byOrder.findIndex((orderedUnion) => orderedUnion.id === parentId);

    if (parentIndex !== -1) {
      byParent.splice(parentIndex + 1, 0, union);
    } else {
      byParent.push(union);
    }
  });

  return byParent;
};

export type GetUnionsStringUnions = Pick<gqlTalentProfileUnion, 'name' | 'code' | 'sortOrder'> & {
  parentObject?: Pick<gqlTalentProfileUnion, 'name' | 'id'>;
};

export const getUnionsString = (unions: GetUnionsStringUnions[], truncateUnions?: boolean): string => {
  if (!unions) return '';

  const filteredUnions = unions.filter((union) => union.code !== TALENT_UNION.SAG_AFTRA);
  const sortedUnions = sortUnions(filteredUnions);
  const unionStringArray = sortedUnions.map((union, index) => {
    const unionName = union.parentObject ? `${union.parentObject.name} ${union.name}` : union.name;

    // If it's last element, there shouldn't be any separator
    if (index === sortedUnions.length - 1) {
      return unionName;
    }

    // Normally leave a separator
    return `${unionName}, `;
  });

  const unionCharsCount =
    unionStringArray && unionStringArray.length > 2 ? _sumBy(unionStringArray, (union) => union.length) : 0;

  return unionCharsCount > 24 && truncateUnions
    ? `${_take(unionStringArray, 3).join('')} + ${sortedUnions.length - 3}`
    : unionStringArray.join('');
};

export const isPremiumMembership = (billingItems) => {
  return (
    billingItems?.length > 0 &&
    billingItems
      .filter((b) => b.itemCode !== BILLING_ITEM_CODES.SYSTEM_ACCESS)
      .every((item) => item.credit === BILLING_CREDITS_MODEL.UNLIMITED)
  );
};

export const isTalentViewedOnRepsWithBasicMembership = (isReps, billingItems): boolean => {
  return isReps && !isPremiumMembership(billingItems);
};

export const getCreditAllowanceByMediaType = (mediaAssetType, billingItems): number => {
  const itemCodes = {
    [MEDIA_TYPE.PHOTO]: BILLING_ITEM_CODES.PHOTO_UPLOAD,
    [MEDIA_TYPE.AUDIO]: BILLING_ITEM_CODES.AUDIO_UPLOAD,
    [MEDIA_TYPE.VIDEO]: BILLING_ITEM_CODES.VIDEO_UPLOAD,
  };

  if (!billingItems?.length) return 0;

  if (isPremiumMembership(billingItems)) return BILLING_CREDITS_MODEL.UNLIMITED;

  const credits = billingItems
    .filter((b) => b.itemCode !== BILLING_ITEM_CODES.SYSTEM_ACCESS)
    .find((item) => item.itemCode === itemCodes[mediaAssetType])?.credit;

  return credits || 0;
};

export const checkIfSufficientCredits = (mediaAssetType, billingItems): boolean => {
  if (isPremiumMembership(billingItems)) return true;

  const credits = getCreditAllowanceByMediaType(mediaAssetType, billingItems);

  return credits === BILLING_CREDITS_MODEL.UNLIMITED ? true : credits > BILLING_CREDITS_MODEL.MUST_PURCHASE;
};

export const checkIfMediaCanBeUploaded = (mediaAssetType, isReps, billingItems): boolean => {
  if (!isTalentViewedOnRepsWithBasicMembership(isReps, billingItems)) {
    return true;
  }

  return checkIfSufficientCredits(mediaAssetType, billingItems);
};

export const getUnionsNames = (unions) => {
  if (!unions) return [];

  const [children, parents] = _partition(unions, (union) => union.parentObject);
  const unionsNames = [];

  children.forEach((child) => {
    parents.forEach((parent) => {
      if (child.parentObject.id === parent.id) {
        unionsNames.push({ id: parent.id, name: `${parent.name}, ${child.name}` });
      } else {
        unionsNames.push({ id: parent.id, name: parent.name });
      }
    });
  });

  return unionsNames;
};

export const useProfileHeight = (unitSystemCode: string, height?: any): string => {
  const { t } = useTranslation();

  if (!height || _isEmpty(height)) return '';

  if (height.IMPERIAL_FEET_INCHES && unitSystemCode === UNIT_SYSTEM.IMPERIAL) {
    return height.IMPERIAL_FEET_INCHES.replace(/'/g, ` ${t('common:measurement.unit.ft')}`).replace(
      /"/g,
      ` ${t('common:measurement.unit.in')}`
    );
  }

  if (height.METRIC_CENTIMETERS && unitSystemCode === UNIT_SYSTEM.METRIC) {
    return `${height.METRIC_CENTIMETERS} ${t('common:measurement.unit.cm')}`;
  }

  return '';
};

export const useProfileWeight = (unitSystemCode: string, weight?: any): string => {
  const { t } = useTranslation();

  if (!weight || _isEmpty(weight)) return '';

  if (unitSystemCode === UNIT_SYSTEM.IMPERIAL && weight?.IMPERIAL_LBS) {
    return `${weight.IMPERIAL_LBS} ${t('common:measurement.unit.lbs')}`;
  }

  if (unitSystemCode === UNIT_SYSTEM.METRIC && weight?.kgRounded) {
    return `${weight.kgRounded} ${t('common:measurement.unit.kg')}`;
  }

  return '';
};

type UseRoleDisplayText = (rateSummary: string, paidType?: string) => string;

export const useRoleRateDisplayText: UseRoleDisplayText = (rateSummary, paidType) => {
  const { t } = useTranslation();

  switch (paidType) {
    case PAID_TYPE.YES:
      return rateSummary;
    case PAID_TYPE.NO:
      return t('common:role.rate.unpaid');
    case PAID_TYPE.PAY_IS_DEFERRED:
      return t('common:role.rate.deferred');
    case PAID_TYPE.UNLISTED:
      return t('common:role.rate.notListed');
    default:
      return ``;
  }
};

export const getLocationsFrom = (countries, dmas) => {
  const submissionsFromDmas = [];
  const submissionsFromRegions = [];
  const submissionsFromCountries = [];

  if (dmas && dmas.length) {
    const dmaIds = dmas.map((d) => d.id);

    countries.forEach((country) => {
      if (country.regions && country.regions.length) {
        const selectedRegions = [];

        country.regions.forEach((region) => {
          const allDmas = region.dmas.filter((d) => dmaIds.indexOf(d.id) > -1);

          if (allDmas.length === region.dmas.length) {
            selectedRegions.push(region.name);
          } else {
            allDmas.forEach((dma) => {
              submissionsFromDmas.push(dma.name);
            });
          }
        });

        if (selectedRegions.length === country.regions.length) {
          submissionsFromCountries.push(country.name);
        } else {
          selectedRegions.forEach((region) => {
            submissionsFromRegions.push(region);
          });
        }
      }
    });
  }

  return [].concat(submissionsFromCountries, submissionsFromRegions, submissionsFromDmas);
};

export const getNoteIcon = (note) => {
  const foundNote = profileConstants.NOTE_MODIFIER_OPTIONS.find((option) => option.value === note.noteModifier?.code);

  return foundNote ? foundNote.icon : 'permanent-note-black';
};

export const PROFILE_EDIT_PAGES_WITH_MEDIA = {
  SKILLS: 'skills-edit',
  CREDITS: 'credits',
};

export const isFromProfileEditPageWithMedia = (pageName: string): boolean => {
  return Object.values(PROFILE_EDIT_PAGES_WITH_MEDIA).includes(pageName);
};

const getTranformationParamsString = (transformationParams: Record<string, string | number>): string => {
  const transformationParamsList = _reduce(
    Object.keys(transformationParams),
    (acc, key) => {
      if (transformationParams[key]) {
        return acc.concat([`${key}_${transformationParams[key]}`]);
      }

      return acc;
    },
    []
  );

  return `${transformationParamsList}`;
};

export type GetVideoAudioDownloadURLArgs = {
  isVideo?: boolean;
  isAudio?: boolean;
  hasDownloadLink: boolean;
  fileKey: string;
  primaryUrl: string;
  downloadName?: string;
};

const NON_SAFE_CHARS = /[^0-9A-Za-z. ]/gi;
const SPACES = / /g;
const PERIODS = /\./g;

// as per cloudinary docs - all dots should be escaped by %252E, and symbols aren't allowed
// https://cloudinary.com/documentation/transformation_flags
export const sanitizeDownloadFileName = (fileName: string): string => {
  if (typeof fileName !== 'string' || !fileName.length) {
    // generic fallback name
    return 'download';
  }

  return fileName.replace(NON_SAFE_CHARS, '').trim().replace(SPACES, '_').replace(PERIODS, '%252E');
};

// if media has download link
// then include fl_attachment flag to URL and return updated URL
// else return primary url;
// Note: 'fl_attachment' delivers the media as an attachment. When the media's URL is accessed, tells the browser to save the media instead of embedding it in a page
// downloadName - gives possibility to save media with custom name
export const getVideoAudioDownloadURL = ({
  isVideo,
  isAudio,
  hasDownloadLink,
  fileKey,
  primaryUrl,
  downloadName,
}: GetVideoAudioDownloadURLArgs): string => {
  if (!hasDownloadLink) return primaryUrl;

  const fileName = sanitizeDownloadFileName(downloadName);

  if (isVideo) {
    const tranformationParamsString = getTranformationParamsString(videoTransformationParams);

    return primaryUrl.replace(fileKey, `${tranformationParamsString}/fl_attachment:${fileName}/${fileKey}`);
  }

  if (isAudio) {
    const tranformationParamsString = getTranformationParamsString(audioTransformationParams);

    return primaryUrl.replace(fileKey, `${tranformationParamsString}/fl_attachment:${fileName}/${fileKey}`);
  }

  return primaryUrl.replace(fileKey, `fl_attachment:${fileName}/${fileKey}`);
};

export type GetMediaDownloadOriginalUrlArgs = {
  hasDownloadLink: boolean;
  primaryUrl: string;
  fileKey: string;
  downloadName?: string;
};

export const getMediaDownloadOriginalUrl = ({
  hasDownloadLink,
  primaryUrl,
  downloadName,
  fileKey,
}: GetMediaDownloadOriginalUrlArgs): string => {
  if (!hasDownloadLink) return primaryUrl;
  const fileName = sanitizeDownloadFileName(downloadName);
  return primaryUrl.replace(fileKey, `fl_attachment:${fileName}/${fileKey}`);
};

export type GetPhotoDownloadUrlArgs = {
  hasDownloadLink: boolean;
  mediaMigrated: boolean;
  autoRotateDimensions?: boolean;
  showLandscape?: boolean;
  crop?: CropMode;
  wideDimension?: number;
  narrowDimension?: number;
  fileKey: string;
  primaryUrl: string;
  shownTransformation?: gqlMediaTransformation | gqlWfMediaTransformation;
  downloadName?: string;
  downloadOriginal?: boolean;
};

export const getPhotoDownloadUrl = ({
  hasDownloadLink,
  mediaMigrated,
  autoRotateDimensions,
  showLandscape,
  crop,
  wideDimension,
  narrowDimension,
  fileKey,
  primaryUrl,
  shownTransformation,
  downloadName,
  downloadOriginal,
}: GetPhotoDownloadUrlArgs): string => {
  if (!hasDownloadLink) return primaryUrl;

  const fileName = sanitizeDownloadFileName(downloadName);
  if (downloadOriginal) {
    return primaryUrl.replace(fileKey, `fl_attachment:${fileName}/${fileKey}`);
  }

  let tranformationParams = [
    getTranformationParamsString({
      // quality
      q: commonPhotoTransformationParams.q,
    }),
  ];

  if (shownTransformation) {
    const photoTransformationParams = {
      c: 'crop',
      h: shownTransformation.height,
      w: shownTransformation.width,
      x: shownTransformation.xAxis,
      y: shownTransformation.yAxis,
      a: shownTransformation.rotate,
    };

    tranformationParams = tranformationParams.concat(getTranformationParamsString(photoTransformationParams));
  }

  if (!shownTransformation && mediaMigrated) {
    tranformationParams = tranformationParams.concat(
      getTranformationParamsString(migratedPhotoTransformationParams as any)
    );
  }

  const portraitTranformationParams = {
    if: autoRotateDimensions ? 'ar_gt_1' : undefined,
    w: wideDimension,
    h: narrowDimension,
    c: crop,
  };

  tranformationParams = tranformationParams.concat(getTranformationParamsString(portraitTranformationParams));
  if (autoRotateDimensions) {
    const landScapeTranformationParams = {
      if: 'else',
      w: showLandscape ? wideDimension : narrowDimension,
      h: showLandscape ? narrowDimension : wideDimension,
      c: crop,
      ...(showLandscape && {
        c: 'pad',
        b: 'black',
      }),
    };

    tranformationParams = tranformationParams.concat(getTranformationParamsString(landScapeTranformationParams));
  }

  const tranformationParamsString = tranformationParams.filter((param) => param !== '').join('/');

  return primaryUrl.replace(fileKey, `${tranformationParamsString}/fl_attachment:${fileName}/${fileKey}`);
};

const getAllowedCountriesArrayFromEnv = (): string => getEnvironment()['ALLOWED_COUNTRIES'];

export const getAllowedInstances = (): Array<{ [key: string]: any }> => {
  const allowedCountries = getAllowedCountriesArrayFromEnv();

  if (allowedCountries) {
    const allowedCountriesList = allowedCountries.split(/,|\|/); // split by "," or by "|"

    if (allowedCountriesList?.[0] !== NO_COUNTRY_RESTRICTIONS) {
      return instanceConstants.filter((item) => allowedCountriesList.includes(item.region));
    }
  }

  // the default array is hardcoded so we won't get empty array
  return instanceConstants;
};

export const getAllowedLocations = (): {
  isUsCaAllowed: boolean;
  isGbIeAllowed: boolean;
  isEuAllowed: boolean;
  isAuNzAllowed: boolean;
  isLAAllowed: boolean;
  areAdditionalAllowed: boolean;
} => {
  const allowedCountries = getAllowedCountriesArrayFromEnv().split(',');
  const noRestrictions = allowedCountries.includes(NO_COUNTRY_RESTRICTIONS);

  const check = (countryCodes: string[]): boolean => countryCodes.every((val) => allowedCountries.includes(val));

  return {
    isUsCaAllowed: noRestrictions,
    isGbIeAllowed: noRestrictions || check([COUNTRY.GB, COUNTRY.IE]),
    isEuAllowed: noRestrictions || check([COUNTRY.FR, COUNTRY.ES]),
    isAuNzAllowed: noRestrictions || check([COUNTRY.NZ, COUNTRY.AU]),
    isLAAllowed:
      noRestrictions || check([COUNTRY.MX, COUNTRY.BR, COUNTRY.VE, COUNTRY.PE, COUNTRY.CL, COUNTRY.AR, COUNTRY.CO]),
    areAdditionalAllowed: noRestrictions || check([COUNTRY.ZA]),
  };
};

type MediaAliasType = {
  id: string | number;
  name: string;
};

export const mapMediaAliases = (
  mediaArr: (gqlMedia | gqlWfMedia | BaseMediaFragment)[],
  mediaAlias: MediaAliasType[]
): MediaAliasType[] => {
  const mediaAliasIds = mediaAlias.slice().map((m) => m.id);

  const filteredMedias = mediaArr.reduce((result, m) => {
    if (!mediaAliasIds.includes(m.mediaId)) {
      return result.concat({
        id: m.mediaId,
        name: m.name,
      });
    }

    return result;
  }, []);

  return mediaAlias.concat(filteredMedias);
};

export const getAgeText = (dateOfBirth: Date): string => {
  let age = 0;
  let ageSuffix = '';

  if (dateOfBirth) {
    const monthsCount = moment().diff(dateOfBirth, 'months');

    if (monthsCount < 1) {
      // age in days
      const daysCount = moment().diff(dateOfBirth, 'days');

      age = daysCount;
      ageSuffix = daysCount > 0 ? 'd' : '';
    } else if (monthsCount >= 1 && monthsCount < 24) {
      // age in months
      age = monthsCount;
      ageSuffix = 'm';
    } else {
      // age in years
      age = Math.floor(monthsCount / 12);
    }
  }

  if (age === 0) return '';

  return `${age}${ageSuffix}`;
};

export type ContactType = {
  value: string;
  whose: 'Talent' | 'Rep';
};

type GetCDProfileContactInfoResult = {
  phone: ContactType;
  email: ContactType;
};

export const getCDProfileContactInfo = (profile: gqlProfile): GetCDProfileContactInfoResult => {
  const repOrg = profile?.organizations?.[0] || profile?.organization;

  const getProfilePhoneNumber = (): ContactType | null => {
    // do not display phone number for ADD_IN profile
    if (
      _isBoolean(profile?.isPersonal) &&
      !profile?.isPersonal &&
      _isBoolean(profile?.isRepresented) &&
      !profile?.isRepresented
    )
      return null;

    const profilePhone = profile?.contactInfo?.phone || profile?.phone;

    if (_isEmpty(repOrg) && profilePhone) {
      return {
        value: profilePhone,
        whose: 'Talent',
      };
    }

    const repOrgPhone = repOrg?.phone?.[0];

    if (repOrgPhone) {
      return {
        value: repOrg?.phone?.[0],
        whose: 'Rep',
      };
    }

    const repOrgContactInfo = _find(repOrg?.contacts, { mainContact: true });

    if (repOrgContactInfo?.phone) {
      return {
        value: repOrgContactInfo?.phone,
        whose: 'Rep',
      };
    }

    const parentOrgPhone = repOrg?.parentOrganization?.phone?.[0];

    if (parentOrgPhone) {
      return {
        value: parentOrgPhone,
        whose: 'Rep',
      };
    }

    const parentOrgContactInfo = _find(repOrg?.parentOrganization?.contacts, { mainContact: true });

    if (parentOrgContactInfo?.phone) {
      return {
        value: parentOrgContactInfo?.phone,
        whose: 'Rep',
      };
    }

    return null;
  };

  const getProfileEmail = (): ContactType | null => {
    // do not display email for ADD_IN profile
    if (
      _isBoolean(profile?.isPersonal) &&
      !profile?.isPersonal &&
      _isBoolean(profile?.isRepresented) &&
      !profile?.isRepresented
    )
      return null;

    const profileEmail = profile?.contactInfo?.email;

    if (_isEmpty(repOrg) && profileEmail) {
      return {
        value: profileEmail,
        whose: 'Talent',
      };
    }

    const repOrgContactInfo = _find(repOrg?.contacts, { mainContact: true });

    if (repOrgContactInfo?.email) {
      return {
        value: repOrgContactInfo?.email,
        whose: 'Rep',
      };
    }

    const parentOrgContactInfo = _find(repOrg?.parentOrganization?.contacts, { mainContact: true });

    if (parentOrgContactInfo?.email) {
      return {
        value: parentOrgContactInfo?.email,
        whose: 'Rep',
      };
    }

    return null;
  };

  return {
    phone: getProfilePhoneNumber(),
    email: getProfileEmail(),
  };
};

export const useEthnicAppearance = (ethnicAppearances: readonly gqlEthnicAppearance[]): string => {
  const { t } = useTranslation();

  if (ethnicAppearances?.length === Object.keys(ETHNIC_APPEARANCE).length) return t('common:role.ethnicAppearance.any');

  if (ethnicAppearances?.length >= 3) return t('common:role.ethnicAppearance.multiple');

  if (ethnicAppearances?.length >= 1 && ethnicAppearances?.length < 3)
    return ethnicAppearances.map((appearance) => appearance.name).join(', ');

  return t('common:role.ethnicAppearance.any');
};

export const useGenderAppearance = (genderAppearances: readonly gqlGenderAppearance[]): string => {
  const { t } = useTranslation();

  if (genderAppearances?.length === Object.keys(GENDER_APPEARANCE).length) return t('common:role.gender.any');

  if (genderAppearances?.length > 2) return t('common:role.gender.multiple');

  if (genderAppearances?.length >= 1) return genderAppearances.map((gender) => gender.name).join(', ');

  return t('common:role.gender.any');
};

export const changeBodyOverflow = (overflowY: 'hidden' | 'auto'): void => {
  document.querySelector('body').style.overflowY = overflowY;
};

export const getAllNestedOptions = (options: SelectOption[]): SelectOption[] => {
  return _reduce(
    options,
    (acc, option) => {
      if (option.options) {
        return [...acc, ...getAllNestedOptions(option.options)];
      }

      return [...acc, option];
    },
    []
  );
};

export const searchOptions = (searchValue: string, options: SelectOption[]): SelectOption[] => {
  return _reduce(
    options,
    (acc, option) => {
      if (!option?.options && _includes(_toLower(option.label), _toLower(searchValue))) {
        return [...acc, option];
      }

      if (option?.options?.length > 0) {
        const nestedOptions = searchOptions(searchValue, option.options);

        return [...acc, ...(nestedOptions?.length > 0 ? [{ ...option, options: nestedOptions }] : [])];
      }

      return acc;
    },
    []
  );
};

type GetGroupedOptionsByFieldNameResult = {
  keys: ReactText[];
  values: ReactText[];
};

export const getGroupedOptionsByFieldName = (
  isSomeOptionsWithFieldName: boolean,
  options: SelectOption[]
): GetGroupedOptionsByFieldNameResult => {
  if (isSomeOptionsWithFieldName) {
    const groupedOptionsByFieldName = _reduce(
      options,
      (acc, option) => {
        acc.set(option.fieldName, {
          fieldName: option.fieldName,
          fieldLabel: option?.fieldLabel,
          options: [...(acc.get(option.fieldName)?.options || []), option],
        });

        return acc;
      },
      new Map()
    );

    return {
      keys: Array.from(groupedOptionsByFieldName.keys()),
      values: Array.from(groupedOptionsByFieldName.values()),
    };
  }

  return {
    keys: [],
    values: [],
  };
};

const coldStorageMediaStatusList = [MEDIA_STORAGE_STATUS.BAU_MIGRATED, MEDIA_STORAGE_STATUS.CLD_CLEANED];

export const isMediaInColdStorage = (mediaStorageStatusCode: string | MEDIA_STORAGE_STATUS): boolean =>
  coldStorageMediaStatusList.includes(mediaStorageStatusCode as MEDIA_STORAGE_STATUS);

/**
 * Helper function to format large numbers with decimal precision
 * Divides the count by a formatter number (1000 for k, 1000000 for M, etc)
 * Returns whole numbers for exact thousands, otherwise one decimal place
 */
const formatLargeNumber = (count: number, formatterNumber: number): string => {
  const value = count / formatterNumber;
  return count % 1000 === 0 ? Math.floor(value).toString() : (Math.floor(value * 10) / 10).toString();
};

/**
 * Formats numeric counts into human readable strings with appropriate suffixes
 * Used for displaying social media follower counts and other metrics
 *
 * Examples:
 * - Numbers 1-100 show as "<100"
 * - 101-999 show exact number
 * - 1000+ uses k suffix (1.2k, 45k)
 * - 1M+ uses M suffix (1.2M)
 * - 1B+ uses B suffix
 *
 * @param count The number to format
 * @param isTalentProfile If true, returns "No" instead of "0" for zero values
 * @returns Formatted string representation of the count
 */
export const formatMetricCount = (count: number, isTalentProfile?: boolean): string => {
  switch (true) {
    case count <= 0:
      return isTalentProfile ? 'No' : '0';
    case count >= 1 && count <= 100:
      return '<100';
    case count > 100 && count <= 999:
      return `${count}`;
    case count >= 1000 && count <= 999999:
      return `${formatLargeNumber(count, 1000)}k`;
    case count >= 1000000 && count <= 999999999:
      return `${formatLargeNumber(count, 1000000)}M`;
    case count >= 1000000000:
      return `${formatLargeNumber(count, 1000000000)}B`;
    default:
      return 'Invalid count';
  }
};
