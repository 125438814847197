import { TOptions, StringMap } from 'i18next';
import { gqlCurrency } from '@minecraft.graphql-types';

type TranslationKey = string;

export type IntlDateFormatName =
  | 'DAY_SHORT'
  | 'WEEK_SHORT'
  | 'MONTH_SHORT'
  | 'MONTH_LONG'
  | 'MONTH_DAY'
  | 'MONTH_DAY_YEAR'
  | 'MONTH_DAY_YEAR_NUMERIC'
  | 'MONTH_DAY_YEAR_TIME'
  | 'MONTH_DAY_YEAR_TZ'
  | 'MONTH_DAY_YEAR_TIME_TZ'
  | 'MONTH_DAY_YEAR_TIME_AT'
  | 'MONTH_DAY_TIME'
  | 'MONTH_DAY_TIME_TZ'
  | 'MONTH_LONG_DAY_YEAR'
  | 'MONTH_LONG_DAY_YEAR_TIME_TZ'
  | 'YEAR'
  | 'TIME'
  | 'TIME_TZ'
  | 'TIME_12_HOUR';

export interface IntlRelativeTimeParams {
  key?: TranslationKey;
  date: string | Date;
}

export interface IntlDateParams {
  key?: TranslationKey;
  date: string | Date;
  format?: IntlDateFormatName;
  /**
   * The timezone of which the current user is in (typically).  Make sure to
   * get this from the myAccount if possible.
   */
  timeZoneStandardName?: string;
  /**
   * Some times are simply strings that look like times, this means they need to be
   * adjusted to the timezone they were created alongside (think a dropdown of timezone selections)
   * The time-as-string will be adjusted to the timezone selected here.
   */
  destTimeZoneStandardName?: string;
  /**
   * If you have given a timeZoneStandardName AND a destTimeZoneStandardName, you can
   * describe if the UTC should be applied or simply ignored.  Setting this to true
   * will simply ignore the UTC timezone and use the date as written.
   * @example
   * 2022-10-15T05:00:00.000Z = 10/15/2022 5:00 AM
   */
  replaceUtc?: boolean;
}

interface IntlPriceParams {
  key?: TranslationKey;
  price: number;
  currency: gqlCurrency['code'];
}

type IntlDateFormatOptions = {
  weekday?: 'narrow' | 'short' | 'long';
  era?: 'narrow' | 'short' | 'long';
  year?: 'numeric' | '2-digit';
  month?: 'numeric' | '2-digit' | 'narrow' | 'short' | 'long';
  day?: 'numeric' | '2-digit';
  hour?: 'numeric' | '2-digit';
  minute?: 'numeric' | '2-digit';
  second?: 'numeric' | '2-digit';
  timeZoneName?: 'short' | 'long';
  // Time zone to express it in
  timeZone?: string;
  // Force 12-hour or 24-hour
  hour12?: true | false;
};

export type IntlDateFormatOptionsFn = (timeZoneStandardName?: string) => IntlDateFormatOptions;
interface IntlPriceParams {
  key?: TranslationKey;
  price: number;
  currency: string;
}

export type IntlPriceFormatter = (params: IntlPriceParams) => string;

// for possible range units, see
// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/RelativeTimeFormat/format#parameters
export enum RelativeTimeRange {
  Year = 'year',
  Month = 'month',
  Day = 'day',
  Hour = 'hour',
  Minute = 'minute',
  Second = 'second',
}

export type RangesTuple = [
  RelativeTimeRange.Year,
  RelativeTimeRange.Month,
  RelativeTimeRange.Day,
  RelativeTimeRange.Hour,
  RelativeTimeRange.Minute,
  RelativeTimeRange.Second
];

export type IntlRelativeTimeRangeOptions =
  | RelativeTimeRange.Year
  | RelativeTimeRange.Month
  | RelativeTimeRange.Day
  | RelativeTimeRange.Hour
  | RelativeTimeRange.Minute
  | RelativeTimeRange.Second;

export type IntlRelativeTimeAndRange = { time: number; range: IntlRelativeTimeRangeOptions };

export type TranslateFnOptions = TOptions<StringMap>;

export type TranslateFn = (key: string, opts?: TranslateFnOptions) => string;

export type IntlDateFormatter = (params: IntlDateParams) => string;

export type IntlRelativeTimeFormatter = (params: IntlRelativeTimeParams) => string;

export type IntlDateAtTimeFormatter = (params: IntlDateParams) => string;

export enum AppKey {
  ADMIN = 'admin',
  COMMON = 'common',
  CASTING = 'casting',
  TALENT = 'talent',
  REPS = 'reps',
  LOGIN = 'login',
  ONBOARDING = 'onboarding',
  STATIC = 'static',
}
