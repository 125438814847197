import { AppKey } from './types';

export const ES_ENABLED_APPS: { [key: string]: boolean } = {
  [AppKey.REPS]: true,
  [AppKey.CASTING]: true,
  [AppKey.ADMIN]: false,
  [AppKey.LOGIN]: true,
  [AppKey.TALENT]: true,
  [AppKey.STATIC]: true,
};
