import React, { FC } from 'react';
import styled from 'styled-components';
import { DesignSystemBgColor } from 'components/themes';
import { ButtonProps } from './types';
import { Button } from './Button';

export type NamedButtonProps = Omit<ButtonProps, 'color'>;

/**
 * Used for destructive actions
 * https://www.figma.com/file/AiDAD1WpprgfbZI2XV6hU1/Casting-Networks-Design-System?type=design&node-id=209-1587&t=LICmM3cTHCgjkvzS-0
 */
export const WarningButton: FC<NamedButtonProps> = (props) => <Button {...props} color="warning" />;

/**
 * Used for most primary interactions
 * https://www.figma.com/file/AiDAD1WpprgfbZI2XV6hU1/Casting-Networks-Design-System?type=design&node-id=209-1587&t=LICmM3cTHCgjkvzS-0
 */
export const PrimaryButton: FC<NamedButtonProps> = (props) => <Button {...props} color="primary" />;

/**
 * Used for secondary buttons
 */
export const SecondaryButton: FC<NamedButtonProps> = (props) => <Button {...props} color="secondary" />;

/**
 * Used for paywall plans, it is the same as the PrimaryButton, but with different background colors for the different plans.
 * https://www.figma.com/file/IjJRusyRPepkD8Y7GYiuJj/%5BCN%5D-Remove-Free-from-Registration?type=design&node-id=287-20828&mode=design&t=aMRlV2c02qFreY84-0
 */
export const PaywallButton: FC<
  NamedButtonProps & { color?: 'hot' | 'hotTrial' | 'cool' | 'coolTrial' | 'vanilla' | 'spicy' | 'discount' }
> = ({ color = 'vanilla', ...props }) => {
  const buttonColor = ['hot', 'hotTrial', 'cool', 'coolTrial', 'vanilla', 'spicy', 'discount'].includes(color)
    ? color
    : 'vanilla';

  return <Button {...props} color={`${buttonColor}Paywall` as ButtonProps['color']} />;
};

/**
 * Ghost Button is a button without decoration, use this when you need a button spacing and style but without a border or background
 * https://www.figma.com/file/AiDAD1WpprgfbZI2XV6hU1/Casting-Networks-Design-System?type=design&node-id=209-1587&t=LICmM3cTHCgjkvzS-0
 */
export const GhostButton: FC<ButtonProps> = ({ ...props }) => <Button {...props} color="link" />;

/**
 * Wrap an img with this button to turn it into a clickable button.
 * This does not use the same button chris built as it does not
 * have any of the same functionality.
 */

export interface ImageButtonProps {
  unsetSizing?: boolean;
  noPadding?: boolean;
  hoverBgColor?: DesignSystemBgColor;
}
export const ImageButton = styled.button<ImageButtonProps>`
  border: none;
  background: transparent;
  color: inherit;
  height: ${({ unsetSizing }) => (unsetSizing ? undefined : 'fit-content')};
  width: ${({ unsetSizing }) => (unsetSizing ? undefined : 'fit-content')};
  ${(noPadding) => (noPadding ? 'padding: 0;' : '')};
  &:hover {
    ${({ theme, hoverBgColor }): string => {
      const bgColor = theme.designSystem.bgColors[hoverBgColor];
      return bgColor ? `background-color: ${bgColor};` : '';
    }}
  }
`;

/**
 * Used to wrap any component in a valid button if you need to click it.
 * This can be useful for "clickable cards" or "clickable images".
 * Testing this should be the same as testing any other button:
 * assertEl({ role: 'button', name: 'The Button Aria-Label' })
 */
export type ButtonAsWrapperProps = React.ComponentProps<typeof ImageButton> & {
  'aria-label': string;
};

export const ButtonAsWrapper: FC<ButtonAsWrapperProps> = (props: React.ComponentProps<typeof ImageButton>) => {
  return <ImageButton {...{ type: 'button' as const, ...props }} />;
};
