import React, { createContext, FC, useContext, useMemo } from 'react';
import { parseJwt } from '@minecraft.utils';
import { gqlSystemRoleCode } from '@minecraft.graphql-types';
import { Ability } from './ability';
import { TokenParsed } from './types';

// don't import the one from blocs, it causes a dependency cycle
const getAccessToken = (): string => {
  return localStorage.getItem('accessToken');
};

/**
=========================================================
              DEFINE THE CONTEXT SHAPE
=========================================================
*/
export type PermissionsValue = {
  ability: Ability;
};

// features are disabled by default
const PermissionsContext = createContext<PermissionsValue>({
  ability: new Ability([]),
});

/**
=========================================================
              PERMISSION BUILDER FUNCTION
=========================================================
*/
interface DefinePermissionsParams {
  accessToken: TokenParsed;
}

type PermissionBuilder = (params: DefinePermissionsParams) => string[];

const getPermissions: PermissionBuilder = ({ accessToken }) => {
  // We apply the shaerd permissions first, and these do not account for role, even unauthorized users are granted these
  let permissions: string[] = [];
  const systemRole = accessToken?.role?.code || '';

  /**
   * Apply any permissions that are specific to the user's role
   * Any future logic that needs to be applied to permissions
   * should be placed here, today we simply read the role of the user and apply permissions
   */
  if (gqlSystemRoleCode.STUDIO === systemRole) {
    permissions = [
      ...permissions,
      'access:archived-roles',
      'access:disable-open-call-link',
      'access:email-representative',
      'access:generate-open-call-link',
      'access:open-call',
      'access:project-breakdown',
      'access:project-wizard',
      'access:publication-history',
      'default-check:project-series',
      'filter:project-series',
      'share:presentation',
      'sort-status:project',
      'view-last-modified:project',
      'view-status-filter:project',
      'view:rep-shortcode',
    ];
  }

  if (gqlSystemRoleCode.CASTING_DIRECTOR === systemRole) {
    permissions = [
      ...permissions,
      'access:archived-roles',
      'access:disable-open-call-link',
      'access:email-representative',
      'access:generate-open-call-link',
      'access:open-call',
      'access:project-wizard',
      'access:publication-history',
      'filter:project-series',
      'share:presentation',
      'sort-status:project',
      'view-last-modified:project',
      'view-status-filter:project',
      'view:rep-shortcode',
    ];
  }

  if (gqlSystemRoleCode.PROJECT_CREATOR === systemRole) {
    permissions = [
      ...permissions,
      'display:zendesk-support',
      'access:archived-roles',
      'access:email-representative',
      'filter:project-series',
      'view-last-modified:project',
      'view-exclusive-status:project',
    ];
  }

  if (gqlSystemRoleCode.MANAGER === systemRole) {
    permissions = [...permissions];
  }

  if (gqlSystemRoleCode.TALENT === systemRole) {
    permissions = [...permissions, 'display:zendesk-support'];
  }

  if (gqlSystemRoleCode.AGENT === systemRole) {
    permissions = [...permissions];
  }

  if (gqlSystemRoleCode.SHARED_PROJECT_USER === systemRole) {
    permissions = [
      ...permissions,
      'view-owner-column:project',
      'view-status-filter:project',
      'sort-status:project',
      'share:presentation',
    ];
  }

  if (accessToken?.loginAttributes?.service === 'BACKLOT') {
    permissions = [...permissions, 'administer:account'];
  }

  return permissions;
};

/**
=========================================================
              CONTEXT PROVIDER AND HOOK
=========================================================
*/
export const PermissionsProvider: FC = ({ children }) => {
  // Eventually we will be calling the API to verify permissions instead of reading the token
  const token = parseJwt(getAccessToken());

  const ability = useMemo(() => {
    const feRules = getPermissions({ accessToken: token }) ?? [];
    const tokenRules = token?.rules ?? [];

    return Ability.createFromRules([...feRules, ...tokenRules]);
  }, [token]);

  const contextValue = useMemo(() => ({ ability }), [ability]);

  return <PermissionsContext.Provider value={contextValue}>{children}</PermissionsContext.Provider>;
};

export const usePermissions = () => {
  const context = useContext(PermissionsContext);

  if (!context) throw new Error('usePermissions must be used within PermissionsProvider');

  return context;
};
